import { useEffect, useState } from "react";
import {
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom";
import { Socket, io } from "socket.io-client";
import { QueryClient, QueryClientProvider } from "react-query";
import { CastReceiverContext } from "chromecast-caf-receiver/cast.framework";

import Game from './screens/Game';
import Lobby from './screens/Lobby';
import EndGame from './screens/EndGame';
import GameStart from "./screens/GameStart";
import Connection from "./screens/Connection";

import { PLAYZER_GAME_SERVER_URL } from "./config";

import Context from "./contexts/Context";

import { RoomType } from "./types/room";

const CUSTOM_NAMESPACE = "urn:x-cast:com.mmc.poc.playzer.v1";
export const deviceType = process.env.REACT_APP_DEVICE as "cast" | "web";

const queryClient = new QueryClient();

export interface DefaultRouteProps {
    receiverContext?: CastReceiverContext;
}

export default function App() {
    const [ioSocket, setIoSocket] = useState<Socket>();
    const [roomId, setRoomId] = useState<string>();
    const [roomType, setRoomType] = useState<RoomType>();

    let receiverContext: CastReceiverContext | undefined = undefined;

    if (deviceType === "cast") {
        receiverContext = cast.framework.CastReceiverContext.getInstance();
    }
    useEffect(() => {
        if (!roomId && receiverContext) {
            receiverContext.addCustomMessageListener(CUSTOM_NAMESPACE, function(event) {
                    const socket = io(PLAYZER_GAME_SERVER_URL, { withCredentials: true, extraHeaders: { "room": `${event.data.roomId}` }})
                    socket.connect();
                    setIoSocket(socket);
                    setRoomId(event.data.roomId.split("-")[1]);
                }
            );
            receiverContext.start({ disableIdleTimeout: true });
        }
    }, [receiverContext, roomId]);

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Connection />,
        },
        {
            path: "/lobby",
            element: <Lobby receiverContext={receiverContext}/>,
        },
        {
            path: "/game-start",
            element: <GameStart receiverContext={receiverContext}/>,
        },
        {
            path: "/game",
            element: <Game receiverContext={receiverContext}/>,
        },
        {
            path: "/game-ended/:winSoundNumber",
            element: <EndGame receiverContext={receiverContext}/>,
        },
    ]);

    const context = {
        ioSocket,
        setIoSocket,
        roomId,
        setRoomId,
        roomType,
        setRoomType,
      };

    return (
        <QueryClientProvider client={queryClient}>
            <Context.Provider value={context}>
                <RouterProvider router={router} />
            </Context.Provider>
        </QueryClientProvider>
    );
};
