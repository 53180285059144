import axios from "axios";
import useSound from "use-sound";
import { useQuery } from "react-query";
import { QRCodeSVG } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { DefaultRouteProps, deviceType } from "@app/App";

import styles from "@app/assets/styles/lobby.module.scss";

import { GameImages } from "@app/assets/images/game/game";
import { CharacterImages } from "@app/assets/images/character/character";

import { CharacterSound, User } from "@app/types/user";

import { PLAYZER_GAME_SERVER_URL } from "@app/config";

import Context from "@app/contexts/Context";

export default function Lobby(props: DefaultRouteProps) {
  const { receiverContext } = props;

  const {
    ioSocket,
    roomId,
    setIoSocket,
    setRoomId
  } = useContext(Context);

  const gameUrl = "poc-playzer://auth/" + roomId;
  const navigate = useNavigate();
  const [players, setPlayers] = useState<User[]>([]);

  // Sound
  const charactersSound: CharacterSound = {
      astronaut: useSound(require("../assets/sound/lobby/astronautLobby.m4a"))[0],
      cat: useSound(require("../assets/sound/lobby/catLobby.m4a"))[0],
      catWoman: useSound(require("../assets/sound/lobby/catWomanLobby.m4a"))[0],
      punk: useSound(require("../assets/sound/lobby/punkLobby.m4a"))[0],
      viking: useSound(require("../assets/sound/lobby/vikingLobby.m4a"))[0],
      death: useSound(require("../assets/sound/lobby/deathLobby.m4a"))[0],
      alien: useSound(require("../assets/sound/lobby/alienLobby.m4a"))[0],
      physicist: useSound(require("../assets/sound/lobby/physicistLobby.m4a"))[0],
    };

  const lobbyMusic = useSound(require("../assets/sound/menu/lobbyLoop.m4a"), { loop: true, autoplay: true });

  //Query
  const playersQuery = useQuery(["get", "players"], async () => {
      const players: User[] = (await axios.get(`${PLAYZER_GAME_SERVER_URL}/rooms/users/room-${roomId}`)).data;
      setPlayers(players);
    },
    { keepPreviousData: false }
  );

  // Functions
  function userLogout(user: User) {
    if (user.leader) {

      if (ioSocket) {
        ioSocket.disconnect();
        setIoSocket(undefined);
        setRoomId(undefined);
        setPlayers([]);
      }

      if (receiverContext) {
        receiverContext.stop();
      } else {
        navigate("/");
      }
    } else {
      playersQuery.refetch();
    }
  };

  function updateUser(user: User) {
    if (user && user.character) {
      charactersSound[user.character]();
    }
    playersQuery.refetch();
  };

  // Effects
  useEffect(() => {
    if (ioSocket) {
      const exitRoom = () => {
        lobbyMusic[1].stop();
        setRoomId(undefined);
        ioSocket.disconnect();
        setIoSocket(undefined);

        if (receiverContext) {
          receiverContext.stop();
        } else {
          navigate("/");
        }
      };

      const onGameStart = () => {
        lobbyMusic[1].stop();
        navigate(`/game-start`);
        ioSocket.off("gameStart", onGameStart);
        ioSocket.off('userLogin', updateUser);
        ioSocket.off('userSelectCharacter', updateUser);
        ioSocket.off('userUnselectCharacter', updateUser);
        ioSocket.off('userLogout', userLogout);
        ioSocket.off("deleteRoom", exitRoom);
      }

      ioSocket.on("gameStart", onGameStart);
      ioSocket.on('userLogin', updateUser);
      ioSocket.on('userSelectCharacter', updateUser);
      ioSocket.on('userUnselectCharacter', updateUser);
      ioSocket.on('userLogout', userLogout);
      ioSocket.on("deleteRoom", exitRoom);

      return () => {
        ioSocket.off("gameStart", onGameStart);
        ioSocket.off('userLogin', updateUser);
        ioSocket.off('userSelectCharacter', updateUser);
        ioSocket.off('userUnselectCharacter', updateUser);
        ioSocket.off('userLogout', userLogout);
        ioSocket.off("deleteRoom", exitRoom);
      };
    } else {
        if (deviceType !== "cast") {
          lobbyMusic[1].stop();
          setRoomId(undefined);
          setIoSocket(undefined);
          navigate("/");
        }
    }
  }, [ioSocket, lobbyMusic]);

  useEffect(() => {
    playersQuery.refetch();
  }, [roomId]);

  return (
    <div className={styles.lobbyContainer} style={{ background: `url(${GameImages.lobby.url}) center / contain no-repeat`}}>
      <div className={styles.roomInfo}>
        <QRCodeSVG includeMargin={true} size={window.innerHeight / 8} value={gameUrl} bgColor="#E4C67F" fgColor="#2B3B76" />
        Room Id: {roomId}
      </div>
      {players && players?.map((player, index) => {
        if (!player.character) return;
        let className;
        let image;

        switch (player.character) {
          case "viking":
            className = styles.viking;
            image = CharacterImages.viking.lobby
            break;
          case "cat":
            className = styles.cat;
            image = CharacterImages.cat.lobby
            break;
          case "catWoman":
            className = styles.catWoman;
            image = CharacterImages.catWoman.lobby
            break;
          case "astronaut":
            className = styles.astronaut;
            image = CharacterImages.astronaut.lobby
            break;
          case "punk":
            className = styles.punk;
            image = CharacterImages.punk.lobby
            break;
          case "alien":
            className = styles.alien;
            image = CharacterImages.alien.lobby
            break;
          case "death":
            className = styles.death;
            image = CharacterImages.death.lobby
            break;
          case "physicist":
            className = styles.physicist;
            image = CharacterImages.physicist.lobby
            break;
          default:
            break;
        }

        return (
          <div key={index + player.character} className={className} style={{ background: `url(${image}) center / contain no-repeat` }}/>
        );
      })}
    </div>
  );
}
