import { Socket } from "socket.io-client";
import { useEffect, useState } from "react";

import { User } from "@app/types/user";
import { Answers, ClassicAnswer, IntruderAnswer, Question, RoundType } from "@app/types/question";

import { CharacterStatusType, getCharacterImages, getCharacterStatus } from "@app/utils/character";

import styles from "@app/assets/styles/game.module.scss";

interface CharacterProps {
    user: User;
    status: CharacterStatusType;
}

function Character(props: CharacterProps) {
    const { status, user } = props;

    const [currentState, setCurrentState] = useState<CharacterStatusType>();

    useEffect(() => {
        if (status !== "answer") {
            setCurrentState(status);
        } else if (currentState === "buzz") {
            setCurrentState(status);
        }
    }, [status]);

    const characterImages = getCharacterImages(user.character);
    const characterStatus = getCharacterStatus(currentState, characterImages);

    return (
        <div className={styles.characterWrapper}>
            <img src={characterStatus} alt="" />
        </div>
    );
}

interface CharactersPlayingProps {
    players: User[];
    roundType?: RoundType;
    question?: Question;
    playersAnswer?: Answers;
    ioSocket?: Socket;
};

export default function CharactersPlaying(props: CharactersPlayingProps) {
    const { players, roundType, question, playersAnswer, ioSocket } = props;

    const [usersAnswer, setUsersAnswer] = useState<User[]>([]);

    useEffect(() => {
        function userAnswer(user: User, botDefaultWrong: boolean) {
            setUsersAnswer([...usersAnswer, user]);
        }

        if (ioSocket) {
            ioSocket.on('userAnswer', userAnswer);
        }

        return () => {
            if (ioSocket) {
                ioSocket.off('userAnswer', userAnswer);
            }
        }
    }, [usersAnswer]);

    useEffect(() => {
        if (playersAnswer) {
            setUsersAnswer([]);
        }
    }, [playersAnswer]);

    return (
        <div className={styles.charactersRow}>
            {players.map((player) => {
                let currentStatus: CharacterStatusType = "neutral";

                if (playersAnswer) {
                    if (roundType === "Intruder" && "userAnswered" in playersAnswer) {
                        const answers = playersAnswer as IntruderAnswer;
                        if (answers.userAnswered.id === player.id) {
                            if (answers.userAnswered.lastAnswer === answers.wrongAnswer) {
                                currentStatus = "wrong";
                            } else {
                                currentStatus = "good";
                            }
                        }
                    } else {
                        const answers = playersAnswer as ClassicAnswer;
                        answers.usersAnswer.forEach(user => {
                            if (user.character === player.character) {
                                if (user.answer === answers.correctAnswer) {
                                    currentStatus = "good";
                                } else {
                                    currentStatus = "wrong";
                                }
                            }
                        })
                    }
                } else if (usersAnswer.find(user => user.id === player.id)) {
                    currentStatus = "buzz";
                } else if (question && question.answers.length > 0) {
                    if (question && "userShouldAnswer" in question) {
                        if (question.userShouldAnswer.id === player.id) {
                            currentStatus = "thinking";
                        }
                    } else {
                        currentStatus = "thinking";
                    }
                }

                return <Character key={player.id} status={currentStatus} user={player} />;
            })}
        </div>
    );
}