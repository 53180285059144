import axios from "axios";
import useSound from "use-sound";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { DefaultRouteProps } from "@app/App";

import { PLAYZER_GAME_SERVER_URL } from "@app/config";

import Context from "@app/contexts/Context";

import { User } from "@app/types/user";

import { GameImages } from "@app/assets/images/game/game";
import { CharacterImages } from "@app/assets/images/character/character";

import styles from "@app/assets/styles/end-game.module.scss";

const sortByScore = (a: any, b: any) => {
    if (a.score == b.score) {
        return 0;
    }

    return (a.score > b.score) ? -1 : 1;
};

export default function EndGame(props: DefaultRouteProps) {
    const { receiverContext } = props;

    const params = useParams();
    const winSoundNumber: number = params.winSoundNumber ? +params.winSoundNumber : 0;

    const navigate = useNavigate();
    const [players, setPlayers] = useState<User[]>();

    const {
        ioSocket,
        roomId,
        setRoomId,
        setIoSocket,
    } = useContext(Context);

    // Sound
    const soundManager = {
        victory: useSound(require("../assets/sound/game/victory.m4a"), { loop: true, autoplay: true }),
        genius: [
            useSound(require("../assets/sound/genius/win/win1.m4a")),
            useSound(require("../assets/sound/genius/win/win2.m4a")),
            useSound(require("../assets/sound/genius/win/win3.m4a")),
            useSound(require("../assets/sound/genius/win/win4.m4a")),
        ],
    };

    let winnerImage;

    if (players) {
        switch (players[0]?.character) {
            case "viking":
                winnerImage = CharacterImages.viking.win;
                break;
            case "punk":
                winnerImage = CharacterImages.punk.win;
                break;
            case "cat":
                winnerImage = CharacterImages.cat.win;
                break;
            case "catWoman":
                winnerImage = CharacterImages.catWoman.win;
                break;
            case "alien":
                winnerImage = CharacterImages.alien.win;
                break;
            case "death":
                winnerImage = CharacterImages.death.win;
                break;
            case "astronaut":
                winnerImage = CharacterImages.astronaut.win;
                break;
            case "physicist":
                winnerImage = CharacterImages.physicist.win;
                break;
        }
    }

    //Query
    useQuery(["get", "players"], async () => {
            const players: User[] = (await axios.get(`${PLAYZER_GAME_SERVER_URL}/rooms/users/room-${roomId}`)).data;
            players.sort(sortByScore);
            setPlayers(players);
        },
        { keepPreviousData: false }
    );

    useEffect(() => {
        setTimeout(() => {
            soundManager.genius[winSoundNumber][0]();
        }, 4000);

        function exitRoom() {
            soundManager.victory[1].stop();
            setRoomId(undefined);
            if (ioSocket) {
                ioSocket.disconnect();
            }
            if (receiverContext) {
                receiverContext.stop();
            }
            setIoSocket(undefined);
            navigate("/");
        };

        if (ioSocket) {
            ioSocket.on("deleteRoom", exitRoom);
        }
        return () => {
            soundManager.victory[1].stop();
            if (ioSocket) {
                ioSocket.off("deleteRoom", exitRoom);
            }
        }
    }, []);

    return (
    <div className={styles.endGameContainer}>
        <img src={GameImages.characterWin.url} alt=""/>
        <img style={{top: 0}} src={winnerImage} alt=""/>
        <img src={GameImages.buzzerWin.url} alt=""/>
        <img src={GameImages.winBackground.url} alt=""/>
        <div className={styles.endGameInfosWrapper}>
            <div className={styles.topWrapper}>
                <div className={styles.winnerWrapper} style={{ background: `url(${GameImages.winInsert.url}) center / contain no-repeat` }}>
                    <div className={styles.firstText}>Félicitations</div>
                    {players && <div className={styles.winnerName}>{players[0].name}</div>}
                    <div className={styles.secondText}>a gagné la partie !</div>
                </div>
                {players && <div className={styles.winnerScore}>Score: {players[0].score}</div>}
            </div>
            <div className={styles.bottomWrapper}>
                <div className={styles.playersWrapper}>
                    {players && players.map((player, index) => {
                            if (index === 0) return;
                            const characterImg = CharacterImages.allCharacter.find((character) => character.type === player.character)?.url;
                            return (
                                <div key={index} className={styles.playerWrapper}>
                                    <img src={characterImg} alt=""/>
                                    <div className={styles.star} style={{ background: `url(${GameImages.star.url}) center / contain no-repeat` }}>{index + 1}</div>
                                    <div className={styles.playerText}>
                                        <div>{player.name}</div>
                                        <div>Score : {player.score}</div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    </div>
    );
}
