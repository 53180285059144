import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";

import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/styles/index.css';

Sentry.init({
  dsn: "https://53aeb1a3b5bbcf3ad3c44c0814e4cbcf@o4508046549843968.ingest.de.sentry.io/4508046552399952",
  debug: true,
  autoSessionTracking: false,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
